import React, { useState, useEffect, useRef } from 'react'
import {NavLink} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {useParams} from 'react-router-dom';
import Breaking from './Breaking';
// import Election from './Election'
import ElectionHome from './ElectionHome'

const Header =(props)=>{
    const {categoryId} = useParams()
    const inputRef = useRef(null)
    const [theme, setTheme] = useState(true);
    const[csDefine, setCsDefine] = useState('block')
    const [loginStatus, setLoginStatus] = useState(false)
    const initialTime = new Date().getTime()

    const [seachBox, setSearchBox] = useState(false)
    const navLinks = [
        { label: 'Hindi News', to: '/hindi-wire/all' },
        { label: 'English News', to: '/english-wire/all' },

        { label: 'Picture Service', to: '/photo-wire/all/1' },
        { label: 'Video Service', to: '/video-wire/multimedia_videos/1' },
        // { label: 'Breaking News', to: '/breaking-news/1' },


      ];
    
  
      const navItems = navLinks.map((link, index) => (
        <li key={index}>
          <a className={`nav-link scrollto ${props && props.currU && props.currU == link.to ? "active" : ""} ${index === 0 ? '' : ''}`} href={link.to}>
            {link.label}
          </a>
        </li>
      ));
    
    const [mobileCss, setMobileCss] = useState(true)
    const toggleMobileCss = () => {
        setMobileCss(!mobileCss);
      };
    const history = useHistory();
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update
    const year = new Date().getFullYear();
    const locale = 'en';
    const date = `${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}, ${year} \n\n`;
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
		const [search, setSearch] = useState();
    const [isVisible, setIsVisible] = useState(false);

    const logoutUser=()=>{

      const apiD =`https://cms.iansnews.in/api/logout/`
  
    axios({
      method: "post",
      url: apiD,
      headers: { 
        "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
    
    }}).then((res) => {
      //setIsLoading(false);
      if(res && res.data && res.data.status){
        localStorage.clear()
        history.push('/login');
      }
    }).catch(err =>{
      if(err && err.response && err.response.data && err.response.data.detail && (err.response.data.detail=='Invalid token.' || err.response.data.detail=='User inactive or deleted.')){
        localStorage.clear()
        history.push('/login');      
      }
      console.log(err,'eeeee');
  })}
	const [searchHead, SetSHead] = useState('template-search');
    const inputSData=(event)=>{
        localStorage.removeItem(search);
        setSearch(event.target.value);
      }
	const onSubmit = (e) => {
        e.preventDefault()
        localStorage.removeItem(search);

        localStorage.setItem('search', search ? search :'');
          let path = '/search/1';
            SetSHead('template-search')
			//window.reload()
			window.location.pathname=path;
        history.push(path);

        // console.log(conFom)
      }

      const seachBoxpop=()=>{
        if(window && window.location && window.location.pathname){
          let patgTemp =window.location.pathname;
          patgTemp = patgTemp.substring(0,12);
          if (patgTemp == '/all-photos/'){
          setSearchBox(false)
        }else{
          setSearch('')
          setSearchBox(!seachBox)
        }
      }
      }
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      };
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };      


      useEffect(() => {
        if(localStorage && localStorage.length > 0 && localStorage.expiry_time && initialTime >= localStorage.expiry_time){
          logoutUser()
        }
        scrollToTop()
        if(localStorage && localStorage.accessToken){
          setLoginStatus(true)
      }
      if(window && window.location && window.location.pathname){
        let patggTemp =window.location.pathname;
        patggTemp = patggTemp.substring(0,12);
        if (patggTemp == '/all-photos/'){
          setCsDefine('none')
        }else{
          setCsDefine('block')
      }
    }
    if(localStorage && localStorage.length > 0 && localStorage.theme && localStorage.theme == 'white'){
      setTheme(true)
    }else{
      setTheme(false)
    }
      if (seachBox) {
        inputRef.current.focus()
      }        
        const btn = document.getElementById("btn-toggle");
    
        function dark() {
          localStorage.theme = 'dark';
          document.body.classList.add('dark-mode');
          btn.classList.add('dark');
        }
    
        function white() {
          localStorage.theme = 'white';
          document.body.classList.remove('dark-mode');
          btn.classList.remove('dark');
        }

        
        const checkbox = document.getElementById("checkbox")
        checkbox.addEventListener("change", () => {
          document.body.classList.toggle("dark-mode")
        })
        window.addEventListener("scroll", toggleVisibility)
        return () => window.removeEventListener("scroll", toggleVisibility)
      }, [categoryId, seachBox]) 

     
      
      
    return (
        <>
          <div id="topbar"  className={'d-flex align-items-center fixed-top'}>
            <div className="container d-flex justify-content-between">
              <div className="contact-info d-flex align-items-center">
                <a href="#">Last updated on  {date} {time}</a>
                { localStorage && localStorage.length > 0 && localStorage && localStorage.userName &&
        <a href="#">
                    <i className="bi bi-person-fill"></i> Welcome <span className="user-sec">  {localStorage && localStorage.userName ? localStorage.userName :''}</span>
                  </a>
                  }
              </div>
              <div className="d-none d-lg-flex social-links align-items-center">
                <a title="IANS News Threads" target="_blank" href="https://www.threads.net/@iansnews.in">
                <svg fill="#c4c3c3" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
                  <path d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"></path>
                </svg>
                </a>
                <a title="IANS News X" target="_blank" href="https://twitter.com/ians_india">
                <svg fill="#c4c3c3" xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512">
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
                </svg>
                </a>
                <a title="IANS News Facebook" target="_blank" href="https://www.facebook.com/iansnews.in/">
                <i className="bi bi-facebook" aria-hidden="true"></i>
                </a>
                <a title="IANS Instagram" target="_blank" href="https://www.instagram.com/iansnews.in/">
                <i className="bi bi-instagram" aria-hidden="true"></i>
                </a>
                <a title="IANS YouTube" target="_blank" href="https://www.youtube.com/user/iansindia">
                <i className="bi bi-youtube" aria-hidden="true"></i>
                </a>
                <a className="subscribe-btn scrollto" href="/contact-us">
                <i className="bi bi-envelope" aria-hidden="true"></i> subscribe </a>

                <div className="dark_mode_checkbox">
                  <input type="checkbox" className="checkbox" id="checkbox"/>
                  <label for="checkbox" className="checkbox-label">
                  <i className="bi bi-moon-fill"></i>
                  <i className="bi bi-sun-fill"></i>
                  <span className="ball"></span>
                  </label>
                </div>

               
               

              </div>
            </div>
          </div>
          <header id="header" className="fixed-top d-flex align-items-center">
            <div className="container d-flex align-items-center justify-content-between">
              <div className="logo">
                <a className="cbx-logo active" href="/hindi-wire/all/">
                      {/* <img src="/assets/img/logo.png" alt=""/> */}
                      <img className="logo_l" src="/assets/img/logo.png" alt=""/>
                      <img className="logo_d" src="/assets/img/logo-w.png" alt=""/>
                  </a>
              </div>
              <nav id="navbar" className={mobileCss ? 'navbar' : 'navbar order-lg-0 navbar-mobile'}>
                
                <ul>{navItems}
                 
                  {localStorage && localStorage.accessToken &&
                    <li onClick={logoutUser}><NavLink className="getstarted" to="/">LOGOUT</NavLink></li>
                    }
                  {(!localStorage || !localStorage.accessToken) &&
                    <li><NavLink className="getstarted" to="/login">LOGIN</NavLink></li>
                    }                    
                </ul>
                
                <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileCss}></i>
                <i className="bi bi-x-circle close-mobile-nav-toggle" onClick={toggleMobileCss}></i>
              </nav>

            </div>
          </header>
          <Breaking/>
          
          {/* {(!props || !props.login) &&
          <ElectionHome showType={props && props.showType ? true :false}/>
        } */}
        </>
    )
}
export default Header;